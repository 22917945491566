import React from "react"
import {
  ModalWrapper,
  ModalBody,
  ModalClose
} from "../static";
import image1 from 'assets/images/custSlideshow/customer_photo_1.jpg';
import image2 from 'assets/images/custSlideshow/customer_photo_2.jpg';
import image3 from 'assets/images/custSlideshow/customer_photo_3.jpg';
import image4 from 'assets/images/custSlideshow/customer_photo_4.jpg';
import image5 from 'assets/images/custSlideshow/customer_photo_5.jpg';
import image6 from 'assets/images/custSlideshow/customer_photo_6.jpg';
import ReactSlider from "react-slick";

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
};

/* eslint react/prop-types: 0 */
export default function OrderPage({close}) {

  return (
    <ModalWrapper>
        <ModalBody>
            <ModalClose onClick={close} />
            <ReactSlider {...settings}>
                <img src={image1} />
                <img src={image2} />
                <img src={image3} />
                <img src={image4} />
                <img src={image5} />
                <img src={image6} />
            </ReactSlider>
        </ModalBody>
    </ModalWrapper>
  )
}
