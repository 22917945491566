import React from "react"
import {
    Nav, 
    NavList, 
    NavItem, 
    MobileNav, 
    DesktopNav, 
    MobileNavButton, 
    ScrollingNav, 
    ScrollLogo, 
    NavA,
    scrollIt
} from "../static";
import logo from 'assets/images/ratflap_logo_web.svg';


/* eslint react/prop-types: 0 */
export default function Navigation({showScrollNav, menuOpen, toggleMenu}) {
    const NavItems = ()  => (
        <Nav>
            <NavList>
                <NavItem>
                    <NavA onClick={(e) => scrollIt(e, '#home', toggleMenu)} href="#home">HOME</NavA>
                </NavItem>
                <NavItem>
                    <NavA onClick={(e) => scrollIt(e, '#order', toggleMenu)}  href="#order">ORDER</NavA>
                </NavItem>
                <NavItem>
                    <NavA onClick={(e) => scrollIt(e, '#contact', toggleMenu)}  href="#contact">CONTACT</NavA>
                </NavItem>
            </NavList>
        </Nav>
    )

    return (
        <>
            {
                showScrollNav &&
                <ScrollingNav>
                    <ScrollLogo src={logo} />
                    <NavItems />
                </ScrollingNav>
            }

            <MobileNav>
                <MobileNavButton active={menuOpen} onClick={() => toggleMenu(!menuOpen)} />
                { menuOpen && <NavItems /> }
            </MobileNav>
        
            <DesktopNav>
                <NavItems />
            </DesktopNav>
        </>
    )
    
}