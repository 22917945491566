import React from "react"
import {
  Contact, H2, ContactLeft, PW, A, ALink, ContactInner
} from "../static";

export default function ContactPage() {

  return (
    <>
      <Contact>
        <ALink id="contact" />
        <ContactInner>
          <ContactLeft>
            <H2 noTop>Get in touch with us</H2>
            <PW>If you are with a large organisation such as a local council or district management and require a large scale roll out of RATFLAP® then please get in touch.</PW>
            <PW>
              Email: <A blue href="mailto:info@ratflap.com">info@ratflap.com</A>
              <br/>
              Phone: <A blue href="tel:+353866070677">+353 86 607 0677</A>
            </PW>
          </ContactLeft>
        </ContactInner>
      </Contact>
    </>
  )
}
