import React from "react"
import {
  Reviews, H1, Review, ReviewName, P
} from "../static";
import ReactSlider from "react-slick";

const settings = {
  dots: false,
  infinite: true,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

export default function ReviewsSection() {
  return (
    <>
      <Reviews>
        <H1>Hear what our customers have to say</H1>
        <br />
          <ReactSlider {...settings}>

            <Review>
              <P>&quot;The Ratflap is probably the the most effective rat prevention tool I have ever seen&quot;</P>
              <ReviewName>
                Bob Lynch (IRELAND) | Emergency Pest Control
              </ReviewName>
            </Review>
           
            <Review>
              <P>“We’ve fitted up to 40 Ratflaps® recently. All are functioning well and I have some very pleased customers”</P>
              <ReviewName>
                David Parnell (UK) | Pest Control
              </ReviewName>
            </Review>

            <Review>
              <P>&quot;Just a quick email to let you know that there hasn&apos;t been a peep since before our last conversation. Ratflap seems to have done the job. We are all getting our full nights sleep so for that I will be forever grateful.&quot;</P>
              <ReviewName>
                Aoife Angelo &amp; Luca (Co Wexford) | Had a severe rat issue for over 6 years and had this to say 10 days after installation of the Ratflap.
              </ReviewName>
            </Review>
          </ReactSlider>
      </Reviews>
    </>
  )
}
