import React, {useState, useEffect} from "react"
import {
  Contact,
  Design,
  Divider,
  Footer,
  Home,
  Map,
  Order,
  Reviews,
  Slider,
  TopNav,
  Modal
} from 'components'
import {Wrapper} from "../components/static";
import GlobalStyles from 'styles/main'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Helmet from 'react-helmet';


export default function Main() {
  const scrollHeight = 120;
  const [scrollPosition, setScrollPosition] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset >= scrollHeight;
    setScrollPosition(position);
  };
    
  useEffect(() => {
    setScrollPosition(window.scrollY >= scrollHeight);
  }, [])

  useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  return (
    <>
      <GlobalStyles />
      {
        showModal && 
        <Modal close={() => setShowModal(false)} />
      }
      <Helmet title="Ratflap" defer={false}>
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500;700;900&display=swap" rel="stylesheet" />
      </Helmet>
      <TopNav 
        showScrollNav={scrollPosition} toggleMenu={(v) => {
          setMenuOpen(v)
        }} 
        menuOpen={menuOpen} 
      />
    <Wrapper
      style={{
        position: `${menuOpen ? 'fixed' : 'static'}`,
        overflow: `${menuOpen ? 'hidden' : 'visible'}`
      }}
    >
      <Home />
      <Design showModal={() => setShowModal(true)} />
      <Divider />
      <Slider />
      <Reviews />
      <Order />
      <Map />
      <Contact />
      <Footer />
    </Wrapper>
    </>
  )
}
