// globalStyles.js
import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100% ;
  }

  * {
    box-sizing: border-box;
    scroll-behavior: smooth;

  }

  body {
    font-family: Poppins, Helvetica, Sans-Serif;
  }

  .slick-disabled {
    display: none;
  }

`;
 
export default GlobalStyle;