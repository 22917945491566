import React from "react"
import {
  Map, H2, MapLeft, PW, More, Inner, scrollIt, WhiteA
} from "../static";

export default function MapSection() {
  // const linkyPoo = 'https://www.google.com/maps/d/embed?mid=1UXrTtQMS_13G4FDvgrCWr43OT-k&zoom=5';

//   const openInNewTab = () => {
//     const newWindow = window.open(linkyPoo, '_blank', 'noopener,noreferrer')
//     if (newWindow) newWindow.opener = null
// }

  return (
    <Map>
      <Inner>
        <MapLeft>
          <H2 color="white">Use the map to find an installer near you</H2>
          <PW>
            To get a RATFLAP fitted by a professional simply check out our map of professional installers to find one closest to you. If you wish to buy and install a Ratflap yourself then please <WhiteA href="/#order" onClick={(e) => scrollIt(e, '#order')}>get in touch</WhiteA>. We will be happy to help!
          </PW>
          <More bgc={'white'} href="https://www.google.com/maps/d/embed?mid=1UXrTtQMS_13G4FDvgrCWr43OT-k&zoom=5" target="_blank">Find an installer near you</More>
        </MapLeft>
      </Inner>
    </Map>
  )
}
