import React from "react"
import {
  P, 
  H2, 
  More, 
  Half, 
  DesignSection, 
  Rotator, 
  RotatorIcon, 
  ALink, 
  DesignWrapper, 
  Section, 
  scrollIt, 
  DesignHalf, 
  DesignTop,
  ClickP
} from "../static"
import 'react-tridi/dist/index.css';
import icon from 'assets/images/360-icon.png'
import designBg from 'assets/images/ratflap_img_diagram_alt_1.jpg';
import Rotation from 'react-rotation'

/* eslint react/prop-types: 0 */
export default function Design({showModal}) {
  return (
    <DesignWrapper>
      <ALink id="design" />
      <Section>
        <DesignTop>
          <Half>
            <H2 color={'lightNavy'}>INNOVATIVE DESIGN</H2>
            <P width="80%">Designed by a pest controller who understands the biology and behaviour of brown rats, the RATFLAP® is designed to stop rats in their tracks, while not affecting the flow of the sewer. Made entirely from 304 grade stainless steel and precision cut by laser meaning RATFLAP® will last for decades. Taking years of development the device is designed to be a simple &quot;fit &amp; forget&quot; solution and can be fitted by almost anyone although a basic knowledge of sewer flow and layout is recommended.</P>
            <P width="80%">Click <ClickP onClick={showModal}>here</ClickP> to see some customer photos showing examples of the problems rats can be.</P>
            <More onClick={(e) => scrollIt(e, '#slider')} href="#slider">SEE HOW IT WORKS</More>
          </Half>
          <Half>
            <Rotator>
              <RotatorIcon src={icon} />
              <Rotation reverse={true} cycle={true} scroll={false}>
                <img src='360/1.jpg' />
                <img src='360/2.jpg' />
                <img src='360/3.jpg' />
                <img src='360/4.jpg' />
                <img src='360/5.jpg' />
                <img src='360/6.jpg' />
                <img src='360/7.jpg' />
                <img src='360/8.jpg' />
                <img src='360/9.jpg' />
                <img src='360/10.jpg' />
                <img src='360/11.jpg' />
                <img src='360/12.jpg' />
                <img src='360/13.jpg' />
                <img src='360/14.jpg' />
                <img src='360/15.jpg' />
                <img src='360/16.jpg' />
                <img src='360/17.jpg' />
                <img src='360/18.jpg' />
                <img src='360/19.jpg' />
                <img src='360/20.jpg' />
                <img src='360/21.jpg' />
                <img src='360/22.jpg' />
                <img src='360/23.jpg' />
                <img src='360/24.jpg' />
                <img src='360/25.jpg' />
                <img src='360/26.jpg' />
                <img src='360/27.jpg' />
                <img src='360/28.jpg' />
                <img src='360/29.jpg' />
                <img src='360/30.jpg' />
                <img src='360/30.jpg' />
                <img src='360/31.jpg' />
                <img src='360/32.jpg' />
                <img src='360/33.jpg' />
                <img src='360/34.jpg' />
                <img src='360/35.jpg' />
                <img src='360/36.jpg' />
                <img src='360/37.jpg' />
                <img src='360/38.jpg' />
                <img src='360/39.jpg' />
                <img src='360/40.jpg' />
                <img src='360/41.jpg' />
                <img src='360/42.jpg' />
                <img src='360/43.jpg' />
                <img src='360/44.jpg' />
                <img src='360/45.jpg' />
                <img src='360/46.jpg' />
                <img src='360/47.jpg' />
                <img src='360/48.jpg' />
                <img src='360/49.jpg' />
                <img src='360/50.jpg' />
                <img src='360/51.jpg' />
                <img src='360/52.jpg' />
                <img src='360/53.jpg' />
                <img src='360/54.jpg' />
                <img src='360/55.jpg' />
                <img src='360/56.jpg' />
                <img src='360/57.jpg' />
                <img src='360/58.jpg' />
                <img src='360/59.jpg' />
                <img src='360/60.jpg' />
                <img src='360/61.jpg' />
                <img src='360/62.jpg' />
                <img src='360/63.jpg' />
                <img src='360/64.jpg' />
                <img src='360/65.jpg' />
                <img src='360/66.jpg' />
                <img src='360/67.jpg' />
                <img src='360/68.jpg' />
                <img src='360/69.jpg' />
                <img src='360/70.jpg' />
                <img src='360/71.jpg' />
                <img src='360/72.jpg' />
              </Rotation>
            </Rotator>
          </Half>

        </DesignTop>
        
        <DesignSection>
          <DesignHalf>
            <img src={designBg} />
          </DesignHalf>
          <Half right>
            <H2 color={'lightNavy'}>Fit and forget Solution</H2>
            <P width="80%">RATFLAP® is available in all sewer pipe sizes (4inch/100mm, 5inch, 6inch/150mm, 9inch/225mm up to 12 inch/300mm and odd sizes can be made upon request too) RATFLAP® can be retrofitted into old, plastic or clay pipes and new pipe systems too. RATFLAP® can be placed into the upstream or downstream positions depending on your sewer flow layout.</P>
          </Half>
        </DesignSection>
      </Section>
    </DesignWrapper>
  )
}
