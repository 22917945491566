import React from "react"
import {
  Footer, FooterText
} from "../static";
import tricolor from 'assets/images/irish-icon.png'

export default function ContactPage() {
  const date = new Date();

  return (
    <Footer>
      <FooterText>
        Copyright &copy; Andec Supplies Ltd. Ratoath, Meath, Ireland. {date.getFullYear()} VAT No: IE 9811587M
        <img src={tricolor} />
      </FooterText>
    </Footer>
  )
}
