import styled, {keyframes} from "styled-components";
import homeImage from 'assets/images/home-hero.jpg';
import designBg from 'assets/images/ratflap_img_diagram_alt_1.jpg';
import hooses from 'assets/images/ratflap_img_houses.jpg';
import mapBg from 'assets/images/map-bg.jpg';
import arrow from 'assets/images/arrow-light.png'
import arrowDark from 'assets/images/arrow-dark.png'

export const scrollIt = (e, val, func) => {
    e.preventDefault();
    if(func) func(false);
    setTimeout(() => {
        document.querySelector(val).scrollIntoView({
            behavior: 'smooth'
        });
    }, 100);
}


const darkNavy = '#00162a';
const lightNavy = '#154a76';
const lightBlue = '#00a7ff';
const mapBlue = '#0d628c';

const colorsObj = {
  white: 'white',
  darkNavy,
  lightBlue,
  lightNavy
}

const fadeGrow = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9,0.9);
  }

  to {
    opacity: 1;
    transform: scale(1,1);
  }
`;

export const Wrapper = styled.section`
  animation: ${fadeGrow} forwards .3s;
`;

export const Inner = styled.section`
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;
`;

export const Section = styled(Inner)`
  min-height: 100vh;
  width: 100%;
  padding: 20px 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;

  @media(max-width: 900px) {
    padding: 40px;
    min-height: 100%;
    flex-direction: column;
  }
`

export const Half = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: ${p => p.right ? 'flex-end' : 'flex-start'};

  @media(max-width: 900px) {
    width: 100%;
  }
`;

export const HomeSection = styled.section`
  flex-direction: column;
  align-items: flex-start;
  background-image: url(${homeImage});
  background-color: ${darkNavy};
  background-size: contain;
  background-position-x: 100%;
  background-repeat: no-repeat;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background-size: cover;
    background-position-x: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
  }

  @media (max-width: 1024px) {
    background-image: none;

    &:before {
      background-image: url(${homeImage});
    }
  }
  
`

export const NavA = styled.a``;
export const WhiteA = styled.a`
  color: white !important
`;

export const ALink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
`;

export const DesignWrapper = styled.section`
  position: relative;
  background-color: #f5f5f5;
`;


export const DesignSection = styled.section`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  background-image: url(${designBg});
  align-items: center;
  background-size: 50%;
  background-position: 0px 50%;
  background-repeat: no-repeat;
  margin-bottom: 80px;
  
  @media(max-width: 900px) {
    background-image: none;
    flex-direction: column;
  }
`;


export const DesignTop = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media(max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

export const DesignHalf = styled(Half)`
  display: none;

  @media(max-width: 900px) {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  img {
    display: block;
    width: 100%;
  }
`;

export const Rotator = styled.div`
  position: relative;

  &:hover  {
    > img {
      display: none;
    }
  }
`;

export const RotatorIcon = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
  height: 100px;

  @media(max-width: 900px) {
    height: 60px;
  }

`;


export const Reviews = styled.section`
  display: block;
  min-height: 300px;
  padding: 80px;
  background-image: url(${hooses});
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 80px;
  color: white;
  text-align: center;

  @media(max-width: 900px) {
    padding: 40px;
    margin-bottom: 0;
  }

  .slick-slider {
    width: 60%;
    margin: 0 auto;

    @media(max-width: 900px) {
      width: 100%;
    }
  }

  .slick-next,
  .slick-prev {
    background-image: url(${arrow});
    background-repeat: no-repeat;
    width: 40px !important;
    height: 30px !important;
    
    &:before {
      display: none;
    }
    
    &:hover {
      color: transparent;
      outline: none;
      background-image: url(${arrow});
    }
  }

  .slick-next {
    transform: translate(0, -50%) rotate(-90deg);
    right: -60px;

    @media(max-width: 900px) {
      right: -35px;
    }
  }

  .slick-prev {
    left: -60px;
    transform: translate(0, -50%) rotate(90deg);

    @media(max-width: 900px) {
      left: -35px;
    }
  }

`

export const ReviewsContainer = styled.section``;

export const Review = styled.div`
  text-align: center;
  max-width: 100%;
  width: 100% !important;
  display: block;

  > p {
    position: relative;
    margin: 0 auto;
    color: white;
    font-style: italic;
    width: 100%;
      
      &:after {
        content: '';
        display: block;
        height: 2px;
        width: 60px;
        margin: 20px auto;
        background-color: ${lightBlue};
      }

  }
`

export const ReviewName = styled.span`
  color: ${lightBlue};
  display: block;
  text-transform: uppercase;
  max-width: 50%;
  margin: 0 auto;
  font-weight: 600;

  @media(max-width: 900px) {
    max-width: 100%;
  }
`;

export const Quart = styled(Half)`
  width: 30%;

  @media(max-width: 900px) {
    width: 100%;
  }

`;

export const ThreeQuart = styled(Half)`
  width: 65%;

  @media(max-width: 900px) {
    width: 100%;
  }

`;

export const Left = styled.div`
  width: 25vw;

  @media(max-width: 900px) {
    width: 100%;
  }
`

export const H1 = styled.h1`
  color: ${lightBlue};
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  font-size: 30px;
  white-space: nowrap;
  letter-spacing: .2px;

  @media(max-width: 600px) {
    white-space: pre-wrap;
  }

  @media(max-width: 900px) {
    font-size: 20px;
  }
 
  @media(max-width: 1024px) {
    font-size: 24px;
  }
`

export const H2 = styled(H1)`
  color: ${({color}) => colorsObj[color]};
  margin: ${({noTop}) => noTop ? '0 0 20px' : '20px 0' };
`

export const Logo = styled.img`
  display: block; 
  height: 100px;  
  margin: 20px 20px 80px 0;

  @media(max-width: 1024px) {
    height: 80px;  
  }

  @media(max-width: 600px) {
    height: 60px;  
  }
`

export const P = styled.p`
  line-height: 2;
  font-weight: 300;
  letter-spacing: .2px;
  width: ${p => p.width ? p.width : '100%'};
  font-size: 18px;
  color: ${lightNavy};
  min-width: 400px;
  margin: 0 0 10px;

  @media(max-width: 900px) {
    min-width: 100%;
    font-size: 14px;
  }

  @media(max-width: 1024px) {
    font-size: 16px;
    line-height: 1.4;
  }
`;

export const PW = styled(P)`
  color: white;
`

export const ClickP = styled.span`
  text-decoration: underline;
  display: inline-block;
  margin: 0;
  font-weight: bold;
  cursor: pointer;
`

export const More = styled.a`
  display: flex;
  align-items: center;
  color: ${(p) => p.dark ? darkNavy :  lightBlue};
  text-transform: uppercase;
  cursor: pointer !important;
  position: relative;
  padding-left: 30px;
  height: 25px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
  margin-top: 40px;
  transition: all ease 0.3s;
  white-space: nowrap;

  @media(max-width: 1024px) {
    font-size: 16px;
  }
    @media(max-width: 600px) {
      white-space: normal;
    }

  &:hover {
    text-decoration: underline;
  }

  &:before {
    content: "";
    background-color: ${(p) => p.bgc ? colorsObj[p.bgc] :  darkNavy};
    width: 15px;
    height: 100%;
    position: absolute;
    left: 0;
  }

  @media(max-width: 900px) {
    margin-bottom: 40px;
    font-size: 14px;
  }
`

export const A = styled.a`
  color: ${(props) => props.blue ? lightBlue : 'white' };
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Img = styled.img`
  height: auto;
`;

export const Divider = styled.section`
  border-top: 10px solid ${lightBlue};
  border-bottom: 10px solid ${lightBlue};
  background-color: ${darkNavy};
  position: relative;
`

export const Video = styled.video`
  width: 100vw;
  margin-bottom: -7px;
`;

export const SliderSectionSection = styled(Section)`
  @media(max-width: 1024px) {
      padding-bottom: 40px;
  }
`;

export const SliderWrap = styled(ThreeQuart)`
  @media(min-width: 901px) and (max-width: 1200px) {
    width: 75%;
    margin: 0 auto;
  }
`;

export const SliderQuart = styled(Quart)`
  @media(min-width: 901px) and (max-width: 1024px) {
    width: 75%;
    margin: 0 auto;
  }
`;

export const SliderSection = styled.section`
  flex: 1;
  padding: 40px 80px;

  @media(max-width: 900px) {
    padding: 20px;
  }

  .slick-next,
  .slick-prev {
    background-image: url(${arrowDark});
    background-repeat: no-repeat;
    width: 30px !important;
    height: 40px !important;
    
    &:before {
      display: none;
    }
    
    &:hover {
      color: transparent;
      outline: none;
      background-image: url(${arrowDark});
    }
  }

  .slick-next {
    transform: translate(0, -50%) rotate(-180deg);
    right: -40px;
  }
  .slick-prev {
    left: -40px;
    transform: translate(0, -50%) rotate(0deg)q
  }
`;

export const SliderItem = styled.div`
  img {
    width: 100% !important;
  }
`;

export const SliderText = styled(P)`
  font-style: italic;
`;

export const Order = styled(Section)`
  position: relative;

  @media(max-width: 1024px) {
    min-height: 75vh;
  }
`;

export const OrderRight = styled(ThreeQuart)`
  margin-top: -40px;
`;

export const OrderImage = styled.img`
  width: 50%;
  margin: 0 auto;
  min-width: 620px !important;


  @media (max-width: 1200px){
    min-width: 450px !important;
  }
  
  @media (max-width: 1024px){
    min-width: 300px !important;
  }
`;

export const Form = styled.form`
  width: 50%;
  min-width: 300px;
  margin: 0 auto;

  @media(max-width: 600px) {
    width: 100%;
    min-width: 0px;
  }

  @media(min-width: 601px) and (max-width: 1024px) {
    max-width: 75%;

  }
`;

export const FormElement = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto 10px;

  @media (max-width: 1200px) {
    flex-direction: column;

  }
`;

export const Label = styled.label`
  flex: 1;
  font-weight: 500;
  font-size: 16px;
  color: #154a76;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    font-size: 14px;
  }
`;

export const TopSection = styled.section``;

export const Small = styled.small`
  font-size: 14px;
  display: block;
  font-weight: 300;
  margin-bottom: 5px;
`;

export const SmallBlue = styled(Small)`
  color: ${lightBlue};
`;

export const SmallRed = styled(Small)`
  color: red;
`;

export const BottomSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  border-top: 2px solid ${darkNavy};
  padding-top: 20px;
`;

export const Input = styled.input`
  border: 2px solid ${darkNavy};
  width: 300px;
  padding: 5px 20px;
  min-height: 35px;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
  font-family: Poppins, Arial, Helvetica, sans-serif;

  &::placeholder {
    font-family: Poppins, Arial, Helvetica, sans-serif;
    color: #00162a50;
    /* color: ${darkNavy}; */
  }

  @media (max-width: 1024px) {
    /* width: 100%; */
    font-size: 14px;
  }
  
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  background: ${darkNavy};
  color: ${lightBlue};
  margin-bottom: 20px;
  padding: 5px 20px;
  min-height: 35px;
  width: 300px;
  opacity: ${p => p.disabled ? '.5' : '1'};
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: normal;
  font-size: 16px;
  border: 0;
  font-family: Poppins, Arial, Helvetica, sans-serif;

  @media (max-width: 600px) {
    width: 100%;
    font-size: 14px;
  }
`;


export const Select = styled.select`
  width: 150px;
  font-weight: 300;
  font-size: 16px;
  padding: 5px;

  @media(max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    padding: 5px 10px;
  }
`;

export const Contact = styled.div`
  background-color: ${darkNavy};
  position: relative;
`;

export const ContactInner = styled(Inner)`
  @media (min-width: 1500px) {
    padding: 40px 0;
  }
  @media (min-width: 900px) {
    padding: 40px 80px;
  }
  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media(max-width: 900px) {
    flex-direction: column;
    padding: 40px;
  }
`;

export const ContactLeft = styled.div`
  flex-shrink: 2;
  padding-right: 20px;

  @media(max-width: 900px) {
    padding-right: 0;
  }
`;

export const ContactImages = styled.div`

`;

export const ContactRight = styled.div`
  text-align: center;
  flex-grow: 1;
  
  img {
    width: calc(50% - 10px);
    display: inline-block;

    &:first-child {
      margin-right: 20px;
    }

    @media(max-width: 500px) {
      width: 100%;
      display: block;
      margin-bottom: 10px;
      
      &:first-child {
        margin-right: 0;
      }
    }
  }
`;

export const ContactSubText = styled(PW)`
  font-size: 14px;
  margin: 20px auto;
  width: 70%;

  @media(max-width: 900px) {
    font-size: 12px;
  }
`;


export const Footer = styled.footer`
  padding: 20px 40px;
  text-align: center;
`;

export const FooterText = styled(P)`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  display: flex;  
  align-items: center;
  justify-content: center;
  
  img {
    margin-left: 10px;
  }
`;

export const MapHolder = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 120px;

  @media(max-width: 900px) {
    padding-top: 0;
    flex-wrap: wrap;
  }
`;

export const Map = styled.section`
  padding: 40px 80px;
  min-height: 100vh;
  background-image: url(${mapBg});
  background-size: cover;
  background-color: ${mapBlue};
  border-top: 5px solid ${lightBlue};
  border-bottom: 5px solid ${lightBlue};
  position: relative;
  display: flex;

  @media(max-width: 900px) {
    background-image: none;
    padding: 40px;
    margin-bottom: 0;
    flex-direction: column;
    min-height: 0px;
  }

  iframe {
    height: 100%;
    width: 90%;

    @media(max-width: 900px) {
      min-height: 500px;
      width: 100%;
    }
  }
`;


export const MapLeft = styled(Quart)`
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const MapButton = styled(Button)`
  width: 100%;

  @media(min-width: 900px) {
    display: none;
  }
`;

const fadeFlap = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Nav = styled.nav`
  position: absolute;
  top: 0;
  padding-top: 20px;
  right: 80px;
  z-index: 9;
  transition: all ease 0.3s;

  @media(max-width: 900px) {
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: ${darkNavy};
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${fadeFlap} forwards 0.4s;
  }
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-left: -40px;

  @media(max-width: 900px) {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 -40px;
  }
`;

export const NavItem = styled.li`
  border-right: 2px solid ${lightBlue};

  &:last-child {
    border-right: none;
  }

  @media(max-width: 900px) {
    border: none !important;
    margin-bottom: 20px;
  }

  a {
    display: block;
    padding: 0 15px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    color: ${lightBlue};

    @media(max-width: 900px) {
      color: white;
    }
  }
`;

export const MobileNav = styled.div`
  display: none;

  @media(max-width: 900px) {
    display: block;
  }
`;

export const DesktopNav = styled.div`
  display: block;

  @media(max-width: 900px) {
    display: none;
  }
`;

export const MobileNavButton = styled.div`
  height: 40px;
  width: 40px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10;
  transition: all ease 0.3s;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 3px;
    position: relative;
    left: 0;
    background-color: ${lightBlue};
    transition: all ease 0.3s;
  }

  &:before {
    top: ${(p) => p.active ? '16px' : '0'};
    transform: ${(p) => p.active ? 'rotate(-45deg)' : 'rotate(0deg)'}
  }

  &:after {
    bottom: -13px;
    transform: ${(p) => p.active ? 'rotate(45deg)' : 'rotate(0deg)'}
  }

`;

const flopIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const ScrollingNav = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 80px;
  z-index: 999;
  background-color: ${darkNavy};
  animation: ${flopIn} 0.4s forwards;

  @media(max-width: 900px) {
    display: none;
  }
`;

export const ModalWrapper = styled.section`
  animation: ${fadeGrow} forwards .3s;
  position: fixed;
  background-color: rgba(0,0,0,0.9);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;

  .slick-list,
  .slick-slider,
  .slick-track,
  .slick-slide > div,
  .slick-slide {
    height: 100% !important;
  }

  .slick-slide > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-slider {
    width: 100%;
    margin: 0 auto;
  }

  .slick-next,
  .slick-prev {
    background-image: url(${arrow});
    background-repeat: no-repeat;
    width: 40px !important;
    height: 30px !important;
    position: fixed;
    
    &:before {
      display: none;
    }
    
    &:hover {
      color: transparent;
      outline: none;
      background-image: url(${arrow});
    }
  }

  .slick-next {
    right: 30px;
    transform: translate(0, -50%) rotate(-90deg);

    @media(max-width:600px) {
      right: 0;
    }
  }

  .slick-prev {
    left: 30px;
    transform: translate(0, -50%) rotate(90deg);

    @media(max-width:600px) {
      left: 0;
    }
  }
`;

export const ModalBody = styled.section`
  width: 75%;
  height: 75vh;
  margin: 0 auto;
  position: relative;
  display: block;

  img {
    width: auto !important;
    display: block !important;
    max-width: 100% !important;
    height: auto !important;
    max-height: 100% !important;
    margin: 0 auto;
  }
` 

export const ModalClose = styled.div`
  position: fixed;
  top: 20px;
  right: 45px;
  width: 20px;
  height: 40px;
  opacity: .5;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: white;
  }

  &:before {
    left: 5px;
    transform: translateX(9px) rotate(45deg);
  }
  
  &:after {
    right: 5px;
    transform: rotate(-45deg);
  }

`

export const ScrollLogo = styled(Logo)`
  height: 50px;
  margin: 0;
`;