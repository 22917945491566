import React from "react"
import {
  H2, P, SliderText, More, SliderSection, SliderQuart, SliderWrap, SliderSectionSection, ALink, SliderItem
} from "../static";
import slide0 from '../../assets/images/slideshow/img_slideshow_00.jpg'
import slide1 from '../../assets/images/slideshow/img_slideshow_01.jpg'
import slide2 from '../../assets/images/slideshow/img_slideshow_02.jpg'
import slide3 from '../../assets/images/slideshow/img_slideshow_03.jpg'
import ReactSlider from "react-slick";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
};

export default function Slider() {
  return (
    <SliderSectionSection>
      <ALink id="slider" />
      <SliderQuart>
        <H2 color={'lightNavy'}>See how it works</H2>
        <P>
          RATFLAP® is designed as a solution to stop rats gaining access to your drains without the need for laying poisons or traps meaning it is an environmentally friendly option. How it works is simple but highly effective. Scroll through the images using the arrows to see how it works.
        </P>
        <More href="/Ratflap_Installation_Instructions.pdf" target="_blank">DOWNLOAD INSTALLATION INSTRUCTIONS</More>
      </SliderQuart>
      <SliderWrap>
        <SliderSection>
          <ReactSlider {...settings}>
              <SliderItem>
                <img src={slide0} />
                <SliderText>The RATFLAP® can be fit to all old and modern waste sewer systems and can be placed in upstream or downstream positions depending on your sewer flow layout.</SliderText>
              </SliderItem>
              <SliderItem>
                <img src={slide1} />
                <SliderText>Take note of the direction of the flow of water and the arrows for correct placement.</SliderText>
              </SliderItem>
              <SliderItem>
                <img src={slide2} />
                <SliderText>The RATFLAP® couldn&quot;t be easier to install. Simply apply pressure to the outer body of the device and place in the sewer pipe opening. (Please refer to this <a href="/Ratflap_Installation_Instructions.pdf">size template</a> if you are unsure of what size to order)</SliderText>
              </SliderItem>
              <SliderItem>
                <img src={slide3} />
                <SliderText>For UK only: Install the RATFLAP® using the supplied fittings. Refer to the enclosed instructions for correct installation.</SliderText>
              </SliderItem>
          </ReactSlider>
        </SliderSection>
      </SliderWrap>
    </SliderSectionSection>
  )
}
