import React from "react"
import {
  Divider, Video
} from "../static"
import video from 'assets/videos/main.mp4'
import videoWebm from 'assets/videos/main2.webm'

export default function Design() {

  return (
    <>
      <Divider>
        <Video preload='auto' autoPlay loop muted>
          <source src={video} type="video/mp4" /> 
          <source src={videoWebm} type="video/webm" /> 
          Your browser does not support the video tag.
        </Video>
      </Divider>
    </>
  )
}
