import React from "react"
import {
  Section,
  H1,
  Logo,
  PW,
  More,
  Left,
  HomeSection,
  ALink,
  scrollIt,
} from "../static"
import logo from "assets/images/ratflap_logo_web.svg"

export default function Home() {
  return (
    <>
      <ALink id="home" />
      <HomeSection>
        <Section>
          <Left>
            <Logo src={logo} />
            <H1>Keep rats out for good</H1>
            <PW>
              Introducing the RATFLAP®. An innovative, simple and cheap solution
              to the worldwide problem of rats in sewers. RATFLAP® prevents rats
              from moving from main sewer systems into the pipes serving
              properties. The RATFLAP® will stop rats from breaking out of a
              damaged pipe underground and gaining access.
            </PW>
            <More
              onClick={e => scrollIt(e, "#design")}
              bgc={"lightBlue"}
              href="#design"
            >
              Find out more
            </More>
            <div
              style={{
                position: "absolute",
                bottom: "20px",
                right: "20px",
                color: "white",
                fontSize: "12px",
              }}
            >
              RATFLAP IS PATENT PROTECTED: GB 2491633
            </div>
          </Left>
        </Section>
      </HomeSection>
    </>
  )
}
