import React, { useState } from "react"
import axios from "axios"
import {
  H2,
  P,
  More,
  Quart,
  OrderImage,
  FormElement,
  Label,
  Select,
  TopSection,
  BottomSection,
  Input,
  Button,
  Small,
  SmallBlue,
  Form,
  Order,
  OrderRight,
  ALink,
  SmallRed,
  scrollIt,
} from "../static"
import image from "assets/images/img_order.jpg"
import countryList from "./countryList"

export default function OrderPage() {
  const [model, setModal] = useState("")
  const [quantity, setQuantity] = useState("")
  const [country, setCountry] = useState("")
  const [email, setEmail] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)

  const formUrlEncoded = x =>
    Object.keys(x).reduce((p, c) => p + `&${c}=${encodeURIComponent(x[c])}`, "")

  const sendRequest = async e => {
    e.preventDefault()
    try {
      await axios({
        method: "post",
        url: "/form.php",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: formUrlEncoded({
          email,
          model,
          quantity,
          country,
        }),
      })
      return setSubmitted(true)
    } catch (e) {
      setError(true)
    }
  }

  return (
    <Order>
      <ALink id="order" />
      <Quart>
        <H2 color={"lightNavy"}>Order your Ratflap</H2>
        <P width="90%">
          Do you operate a drain cleaning company, pest control company or work
          as part of a local council? If so we can supply you with the RATFLAP®
          in bulk and in various sizes, just email us at{" "}
          <a href="mailtop:orders@ratflap.com">orders@ratflap.com</a>. If you
          are interested please send an order query by filling out the form to
          the right.
        </P>
        <P width="90%">
          If you are unsure what size drain you have please print{" "}
          <a href="/Ratflap_Dimensions_Guide.pdf">this size guide</a> (set to
          100% print scale).
        </P>
        <More onClick={e => scrollIt(e, "#contact")} href="#contact">
          NEED HELP? GET IN TOUCH
        </More>
      </Quart>
      <OrderRight>
        <OrderImage src={image} />
        <Form onSubmit={sendRequest}>
          <TopSection>
            <FormElement>
              <Label>1. Select your model:</Label>
              <Select
                value={model}
                onChange={e => setModal(e.target.value)}
                name="model"
              >
                <option>Choose</option>
                <option value="4-inch">4-inch (100mm)</option>
                <option value="5-inch">5-inch</option>
                <option value="6-inch">6-inch(150 mm)</option>
                <option value="8-inch">8-inch</option>
                <option value="9-inch">9-inch (228mm)</option>
                <option value="12-inch">12-inch (304mm)</option>
                <option value="standard-install">Standard install</option>
                <option value="deep-chamber-install">
                  Deep chamber install pole
                </option>
              </Select>
            </FormElement>
            <FormElement>
              <Label>2. Choose your quantity:</Label>
              <Select
                value={quantity}
                onChange={e => setQuantity(e.target.value)}
                name="quantity"
              >
                <option>Choose</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="5+">Over 5</option>
              </Select>
            </FormElement>
            <FormElement>
              <Label>3. Select your country:</Label>
              <Select
                value={country}
                onChange={e => setCountry(e.target.value)}
                name="country"
              >
                <option value={""}>Please Select</option>
                <option value={"Ireland"}>Ireland</option>
                <option value={"United Kingdom"}>United Kingdom</option>
                {countryList.map((country, i) => (
                  <option key={`${country}-${i}`} value={country}>
                    {country}
                  </option>
                ))}
              </Select>
            </FormElement>
          </TopSection>
          <BottomSection>
            <Input
              name="email"
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter your email address"
              type="email"
            />
            <Button disabled={!model || !quantity || !email}>
              Send your order query
            </Button>
            {submitted && (
              <>
                <SmallBlue>[Your message was sent successfully]</SmallBlue>
                <Small>We endeavour to respond within 24 hours.</Small>
                <Small>
                  For urgent queries email:{" "}
                  <a href="mailto:info@ratflap.com">info@ratflap.com</a>
                </Small>
              </>
            )}
            {error && (
              <>
                <SmallRed>
                  [There was an error submitting your message]
                </SmallRed>
                <Small>
                  Please try again later or contact us at{" "}
                  <a href="mailto:info@ratflap.com">info@ratflap.com</a>
                </Small>
              </>
            )}
          </BottomSection>
        </Form>
      </OrderRight>
    </Order>
  )
}
